<template>
  <div class="upcoming-events">

    <b-container class="pt-5 pb-5">
      <b-row>
        <b-col col md="8" class=" mb-5">
          <b-row>
            <b-col col md="12" class="">
              <h1>Eventos</h1>
              <hr class="line sm" />
            </b-col>

            <b-col cols="12" class="mb-4">
              <h3>
                <span class="light blue-grey-text">2021-10-31 </span>
                <span class="strong blue-grey-text text-lighten-4"> / </span> 
                <span class="strong blue-grey-text text-darken-2">Evento 1</span>
              </h3>
              <p class="lead">Donec vel nisi in ante bibendum pulvinar quis sed massa.
                Ut sit amet velit nibh. Quisque ac elit est. Nulla nec vehicula dolor.
                Mauris nec urna mauris. Proin eu est massa.</p>
            </b-col>
            <b-col cols="12" class="mb-4">
              <h3>
                <span class="light blue-grey-text">2021-10-31 </span>
                <span class="strong blue-grey-text text-lighten-4"> / </span> 
                <span class="strong blue-grey-text text-darken-2">Evento 2</span>
              </h3>
              <p class="lead">Donec vel nisi in ante bibendum pulvinar quis sed massa.
                Ut sit amet velit nibh. Quisque ac elit est. Nulla nec vehicula dolor.
                Mauris nec urna mauris. Proin eu est massa.</p>
            </b-col>
            <b-col cols="12" class="mb-4">
              <h3>
                <span class="light blue-grey-text">2021-10-31 </span>
                <span class="strong blue-grey-text text-lighten-4"> / </span> 
                <span class="strong blue-grey-text text-darken-2">Evento 3</span>
              </h3>
              <p class="lead">Donec vel nisi in ante bibendum pulvinar quis sed massa.
                Ut sit amet velit nibh. Quisque ac elit est. Nulla nec vehicula dolor.
                Mauris nec urna mauris. Proin eu est massa.</p>
            </b-col>
            <b-col cols="12" class="mb-4">
              <h3>
                <span class="light blue-grey-text">2021-10-31 </span>
                <span class="strong blue-grey-text text-lighten-4"> / </span> 
                <span class="strong blue-grey-text text-darken-2">Evento 4</span>
              </h3>
              <p class="lead">Donec vel nisi in ante bibendum pulvinar quis sed massa.
                Ut sit amet velit nibh. Quisque ac elit est. Nulla nec vehicula dolor.
                Mauris nec urna mauris. Proin eu est massa.</p>
            </b-col>

          </b-row>
        </b-col>

        <b-col cols="4">
          <Sidebar msg="Paginas" />
        </b-col>

      </b-row>
    </b-container>

  </div>
</template>

<script>
import Sidebar from '@/components/layout/Sidebar.vue';

export default{
  name: 'UpcomingEvents',
  components: {
    Sidebar,
  },
}
</script>
